import { render, staticRenderFns } from "./dataalarMessage.vue?vue&type=template&id=768c1a89&scoped=true&"
import script from "./dataalarMessage.vue?vue&type=script&lang=js&"
export * from "./dataalarMessage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "768c1a89",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/workspace/ACP-TEST/aiparkcity_acb/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('768c1a89')) {
      api.createRecord('768c1a89', component.options)
    } else {
      api.reload('768c1a89', component.options)
    }
    module.hot.accept("./dataalarMessage.vue?vue&type=template&id=768c1a89&scoped=true&", function () {
      api.rerender('768c1a89', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dataalarmTotal/dataalarMessage.vue"
export default component.exports